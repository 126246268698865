import React from "react";
import Image from "next/image";

import { ProjectShape, RightArrow } from "../svg";
// images
import port_1 from "@/assets/img/home-02/steps/cons1.jpeg";
import port_2 from "@/assets/img/home-02/steps/cons2.jpeg";
import port_3 from "@/assets/img/home-02/steps/clean1.jpeg";
import port_4 from "@/assets/img/home-02/steps/clean2.jpeg";

import Link from "next/link";

// portfolio data
const project_data = [
  {
    id: 1,
    img_1: port_1,
    img_2: port_2,
    meta: "Step 1",
    title: "Initial Consultation",
  },
  {
    id: 2,
    img_1: port_3,
    img_2: port_4,
    meta: "Step 2",
    title: "Comprehensive Dental Care",
  },
];

// prop type
type IProps = {
  style_2?: boolean;
};
export default function ProjectFour({ style_2 = false }: IProps) {
  return (
    <div
      className={`tp-project-3-area ${
        style_2 ? "pt-60 pw-project-style" : "pt-130 black-bg"
      }`}
    >
      <div className='container container-1720'>
        {!style_2 && (
          <div className="row justify-content-center">
          <div className="col-xxl-8 col-xl-10">
            <div className="tp-about-2-title-box tp-btn-trigger tp-btn-bounce mb-70 text-start text-xl-center">
              <h2 className="tp-about-2-section-title ">
              Our Dental Care Process
              </h2>
            </div>
          </div>
        </div>
        )}
        <div className='row'>
          <div className='col-xl-12'>
            {project_data.map((item, i) => (
              <div key={item.id} className='tp-project-3-wrap'>
                <div className='row'>
                  <div className='col-xl-4 col-lg-4 col-md-6'>
                    <div className='tp-project-3-thumb pro-img-1'>
                      <Image
                        src={item.img_1}
                        alt='port-img'
                        style={{ height: "auto" }}
                      />
                    </div>
                  </div>
                  <div className='col-xl-4 col-lg-4 col-md-12 order-1 order-lg-0'>
                    <div className='tp-project-3-content text-center'>
                      <span className='tp-project-3-meta'>{item.meta} </span>
                      <h4 className='tp-project-3-title-sm'>
                        {item.title}
                      </h4>
                    </div>
                    <div className='tp-project-3-border color-1 text-center'>
                      <span></span>
                    </div>
                  </div>
                  <div className='col-xl-4 col-lg-4 col-md-6 order-0 order-lg-0'>
                    <div className='tp-project-3-thumb pro-img-2'>
                      <Image
                        src={item.img_2}
                        alt='port-img'
                        style={{ height: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}