import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  Mousewheel,
} from "swiper/modules";
import { SwiperOptions } from "swiper/types";
import Link from "next/link";
import { RightArrow, SvgBgSm } from "../svg";
import sanityClient from "../../../sanity/lib/client";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type HeroBanner = {
  _id: string;
  title: string;
  description: string;
  media: {
    type: "image" | "video";
    image?: {
      asset: {
        url: string;
      };
    };
    video?: {
      asset: {
        url: string;
      };
    };
  };
  page: string;
};

export default function HeroBannerSix() {
  const [heroData, setHeroData] = useState<HeroBanner[]>([]);
  const progressBar = useRef<HTMLSpanElement | null>(null);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "heroBanner"]{
          _id,
          title,
          description,
          media {
            type,
            image {
              asset->{
                url
              }
            },
            video {
              asset->{
                url
              }
            }
          },
          page
        }`
      )
      .then((data) => {
        setHeroData(data);
      })
      .catch(console.error);
  }, []);

  const slider_setting: SwiperOptions = {
    direction: "horizontal",
    loop: false,
    slidesPerView: 1,
    speed: 1000,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: ".tp-shop-next",
      prevEl: ".tp-shop-prev",
    },
    pagination: {
      el: ".tp-slider-dot",
      clickable: true,
      renderBullet: (index, className) => `<div class="${className}"></div>`,
    },
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, phone, email } = formData;

    if (!name || !phone || !email) {
      toast.error("Please fill in all fields");
      return;
    }

    try {
      await axios.post("http://localhost:3001/send-email", {
        name: formData.name,
        email: formData.email,
        subject: "Appointment Request",
        phone: formData.phone,
      });
      toast.success("Appointment request sent successfully");
      setFormData({ name: "", phone: "", email: "" });
    } catch (error) {
      toast.error("Error sending appointment request");
    }
  };

  return (
    <div className="tp-shop-slider-area p-relative">
      <div className="shop-slider-wrapper">
        <Swiper
          {...slider_setting}
          modules={[Navigation, Pagination, Autoplay, EffectFade, Mousewheel]}
          className="swiper-container tp-shop-slider-active"
          onAutoplayTimeLeft={(s, time, progress) => {
            if (progressBar.current) {
              progressBar.current.style.transform = `scaleX(${1 - progress})`;
            }
          }}
        >
          <div className="tp-shop-slider-arrow-box">
            <button className="tp-shop-next">
              <i className="fa-light fa-angle-left"></i>
            </button>
            <button className="tp-shop-prev">
              <i className="fa-light fa-angle-right"></i>
            </button>
          </div>
          {heroData.map((item) => (
            <SwiperSlide key={item._id} className="swiper-slide">
              <div className="tp-shop-slider-bg tp-shop-slider-ovarlay">
                {item.media.type === "image" ? (
                  <div
                    className="tp-shop-slider-thumb"
                    style={{
                      backgroundImage: `url(${item.media.image?.asset.url})`,
                    }}
                  ></div>
                ) : (
                  <video className="tp-shop-slider-thumb" autoPlay loop muted>
                    <source
                      src={item.media.video?.asset.url}
                      type="video/mp4"
                    />
                  </video>
                )}
                <div className="container container-1300">
                  <div className="row">
                    <div className="col-xl-8">
                      <div className="tp-shop-slider-content z-index">
                        <div className="tp-shop-slider-title-box">
                          <h2
                            className="tp-shop-slider-title"
                            dangerouslySetInnerHTML={{ __html: item.title }}
                          ></h2>
                          <span className="tp-shop-slider-subtitle">
                            {item.description}
                          </span>
                        </div>
                        <div className="tp-shop-slider-btn-box">
                          <Link className="tp-shop-btn" href={item.page}>
                            Book appointment
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="fraction-wrapper d-none d-lg-block">
            <div id="paginations"></div>
            <div className="shop-slider-progress-bar">
              <span ref={progressBar}></span>
            </div>
          </div>
        </Swiper>

        {/* Consultation Form Positioned Above Banner Content */}
        <div className="tp-footer-3-area dark-bg p-3 w-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-9 col-md-9 mb-60">
                <div className="text-center">
                  <h4 className="tp-footer-3-title">
                    Get Free Online Consultation
                  </h4>
                  <form
                    onSubmit={handleSubmit}
                    className="tp-footer-3-input-box d-flex flex-wrap flex-lg-nowrap justify-content-center"
                  >
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Name"
                      className="footer-input me-lg-2 mb-2 mb-lg-0"
                      style={{ flex: "1" }}
                    />
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                      className="footer-input me-lg-2 mb-2 mb-lg-0"
                      style={{ flex: "1" }}
                    />
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                      className="footer-input me-lg-2 mb-2 mb-lg-0"
                      style={{ flex: "1" }}
                    />
                    <button
                      type="submit"
                      className="tp-footer-3-btn p-relative hide-on-small"
                    >
                      <div className="icon-container">
                        <span className="icon-1">
                          <RightArrow clr="#ffff" />
                        </span>
                        <span className="icon-2">
                          <SvgBgSm />
                        </span>
                      </div>
                    </button>
                  </form>
                  <div className="d-lg-none mt-2">
                    <button
                      type="submit"
                      className="tp-shop-btn"
                      onClick={handleSubmit}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "25px",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .tp-footer-3-area {
          bottom: 0;
          z-index: 1000;
          padding: 20px;
          background: rgba(0, 0, 0, 0);
          position: absolute;
        }

        .tp-footer-3-input-box {
          flex-direction: row;
        }

        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }

        @media (max-width: 992px) {
          .tp-footer-3-area {
            position: relative;
          }

          .tp-footer-3-input-box {
            flex-direction: column;
          }

          .footer-input {
            margin-bottom: 10px;
          }

          .tp-footer-3-input-box input {
            border: 1px solid #e6b745;
            background-color: black;
          }

          .icon-container {
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .hide-on-small {
            display: none; /* Hide the button on small screens */
          }
        }
      `}</style>
    </div>
  );
}
